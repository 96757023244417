<template>
<div class="my-4">
  <b-container fluid v-if="show">
    <b-row class="mb-4">
      <b-col>
        <b-form inline>
          <b-form-select class="mr-4" v-model="countrySelected" :options="countryOptions" size="lg" style="font-weight: 900"></b-form-select>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="4" class="mb-4" v-for="parent in taxonomy" :key="'parent-' + parent.key">
        <h3>{{ parent.name }}</h3>
        <div v-for="child in parent.children" :key="'child' + child.name">
          <strong>{{ child.name }}</strong>
          <div v-for="item in child.initiatives" :key="'initiative-' + item.id">
            <router-link :to="{ name: 'TaxInitiative', params: { id: item.id }}">
              <span v-if="item.tax_local_rate" class="px-2" style="background-color: red; color: white;">{{ item.tax_local_rate }} %</span>
              <span v-if="item.tax_incentive_rate" class="px-2" style="background-color: green; color: white;">{{ item.tax_incentive_rate }} %</span>
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'

// create delay function
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: 'TaxCountry',
  components: {
  },
  computed: {
    countryOptions: function () {
      const all = this.$store.state.initiativesTax
      let results = all.map(x => x.country)
      results = _.uniq(results)
      results.sort()
      return results
    },
    rows: function () {
      const country = this.$route.params.country
      const results = []
      const all = this.$store.state.initiativesTax
      all.forEach(x => {
        if (x.country === country) {
          results.push(x)
        }
      })
      return results
    },
    taxonomy: function () {
      let parents = []
      const flatData = []
      if (this.rows.length > 0 && Array.isArray(this.rows)) {
        _.each(this.rows, row => {
          if (row.taxonomy.length > 0) {
            row.taxonomy.forEach(taxonomy => {
              taxonomy.parents.forEach(parent => {
                flatData.push({ parent_id: parent.id, parent_name: parent.name, id: taxonomy.id, name: taxonomy.name })
                parents.push(parent)
              })
            })
          }
        })
        parents = _.uniqBy(parents, 'id')
        parents.forEach(parent => {
          parent.key = _.camelCase(parent.name)
          parent.children = _.filter(flatData, { parent_id: parent.id })
          parent.children = _.uniqBy(parent.children, 'id')
        })
      }
      parents.forEach(parent => {
        parent.children.forEach(child => {
          child.initiatives = []
          this.rows.forEach(row => {
            if (row.taxonomy.length > 0) {
              row.taxonomy.forEach(taxonomy => {
                if (taxonomy.id === child.id) {
                  const record = _.omit(row, ['taxonomy'])
                  child.initiatives.push(record)
                }
              })
            }
          })
        })
      })
      parents = _.sortBy(parents, 'name')
      return parents
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "Tax Dashboard: " + this.$route.params.country
    this.$stat.log({ page: 'tax country', action: 'open tax country' })
    if (this.$route.params.country) {
      this.countrySelected = this.$route.params.country
      window.localStorage.setItem('frmCloudCountrySelected', this.countrySelected)
    } else if (window.localStorage.getItem('frmCloudCountrySelected')) {
      this.countrySelected = window.localStorage.getItem('frmCloudCountrySelected')
    } else {
      this.countrySelected = this.countryOptions[0]
    }
    await delay(100)
    this.show = true
    console.log(this.rows)
    console.log(this.taxonomy)
  },
  data () {
    const data = {
      countrySelected: null,
      show: false
    }
    return data
  },
  methods: {
  },
  watch: {
    '$route.params.country': function () {
      this.countrySelected = this.$route.params.country
      document.title = "Tax Dashboard: " + this.$route.params.country
    },
    'countrySelected': async function () {
      window.localStorage.setItem('frmCloudCountrySelected', this.countrySelected)
      if (this.countrySelected !== this.$route.params.country) {
        this.$router.push({ name: 'TaxCountry', params: { country: this.countrySelected } })
        this.show = false
        await delay(100)
        this.show = true
      }
    }
  }
}
</script>

<style>
</style>
